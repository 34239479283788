.login, .logout {
    color: #061C3A;
    font-size: 1.5em;
}

.login-title, .logout-title{
    padding-top: 0.75em;
}

.status{
    padding: 0.5em 0;
    color: orange;
}