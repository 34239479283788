.home-title {
    font-size: 2.8rem;
    color: #061C3A;
    margin: 3rem 0rem 3rem 0rem;
}

.home-description {
    font-size: 1.5rem;
    color: #061C3A;
    margin-left: auto;
    margin-right: auto;
    max-width: 53%;
}

.company-name {
    font-size: 3.0rem;
    color: black;
    padding-top: 2rem;
    word-spacing: 2rem;
}

.company-organization {
    font-size: 2.0rem;
    color: #E7C470;
    word-spacing: 0.5rem;
}