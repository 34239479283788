.opt-main-div {
	font-size: 1.6em;
	border: 7px solid #14315C;
	width: 80.0%;
	height: 80.0%;
	margin: auto;
	min-width: 15.0em;
	margin-top: 2.0%;
	margin-bottom: 2.0%;
}

.scheading {
	padding-top: 0.5em;
}

.opt {
	display: inline-block;
	margin: 0.25em;
	vertical-align: top;
	min-width: 14.0em;
	border: 2.0px solid grey;
	border-radius: 0.5em;
}

.opt-title {
	padding-left: 0.5em;
	text-align: left;
}

.grid-container {
	font-size: 0.75em;
	display: flex;
	justify-content: left;
	gap: 0.25em;
	padding-left: 2.25em;
}

.opt-slct {
	font-size: 0.9em;
	height: 1.5em;
	width: 3.75em;
	color: white;
	background-color: #555;
	border-color: white;
	border-radius: 0.5em;
	margin-bottom: 0.1em;
}

.ctrl-chkbx input[type=checkbox] + label {
	display: block;
	margin: 0.2em;
	cursor: pointer;
	padding: 0.2em;
}

.ctrl-chkbx input[type=checkbox] {
	display: none;
}

.ctrl-chkbx input[type=checkbox] + label:before {
	content: "\2714";
	border: 0.1em solid #000;
	border-radius: 0.2em;
	display: inline-block;
	width: 1.3em;
	height: 1.2em;
	margin-right: 0.2em;
	color: transparent;
	transition: 0.2s;
}

.ctrl-chkbx input[type=checkbox] + label:active:before {
	transform: scale(0);
}

.ctrl-chkbx input[type=checkbox]:checked + label:before {
	color: white;
	background-color: #14315C;
	border-color: #14315C;
	text-align: center;
}

.ctrl-chkbx input[type=checkbox]:disabled + label:before {
	transform: scale(1);
	border-color: #aaa;
}

.ctrl-chkbx input[type=checkbox]:checked:disabled + label:before {
	transform: scale(1);
	color: #aaa;
}

.btns {
    font-size: 1.4rem;
    width: 100px;
	color: #E7C470;
    background-color: #14315C;
    border-color: #14315C;
    border-style: solid;
    border-width: 3px;
    border-radius: 200px;
	margin-right: 10px;
	margin-bottom: 3.0%;
}

.messaging {
    padding: .25em 0em;
    font-size: 1.5em;
    color: orange;
}

.q-mark {
	float: right;
    font-weight: bold;
    font-size: 1.2em;
    line-height: 0.8em;
    border-radius: 50%;
    margin-left: 6px;
    margin-right: 6.0px;
    padding: 0.13em 0.2em 0.09em 0.2em;
    color: white;
    background-color: #33BEF0;
    border: 1px solid;
}

.rtn-ctrl-div {
	text-align: left;
	padding-top: 2.0rem;
	padding-bottom: 4.0rem;
}

.control-group-title {
	padding-top: 1.5rem;
	text-decoration: underline;
}

.modal-title {
	margin: 0.5rem 0 0 0;
	margin-bottom: 1.2rem;
	border-bottom: 1px solid #ccc;
	padding-bottom: 1.5rem;
}

li {
	font-size: 1.1rem;
	list-style-type: none;
	margin: 0.5rem 1.2rem 0 0.5rem;
	text-indent: -2.8rem;
}

.bullet, span + span {
	margin: 0 1.4rem 0 0;
}

.bullet-sub {
	padding-top: 0.5rem;
	margin: 0 0 0 2.7rem;
}

.modal-by-ctrl {
	width: 80.0%;
	// Viewport Height (vh). This unit is based on the height of the viewport. A value of 1vh is equal to 1% of the viewport height.
	max-height: calc(95vh - 210px);
	overflow-y: auto;
	background: white;
	border: 1px solid #ccc;
	box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
	filter: blur(0);
	transform: scale(1);
	opacity: 1;
	visibility: visible;
	padding: 2.0rem;
	margin: 7.5%;
	.close-button {
		cursor: pointer;
		&:after, &:before {
			content: "";
			height: 1.5rem;
			width: 1.5rem;
			border-top: 0.1rem solid #000;
			position: absolute;
			top: 2.0rem;
			right: 1.35rem;
			transform: rotate(-45deg);
		}
		&:before {
			right: 2.4rem;
			transform: rotate(45deg);
		}
		&:hover {
			opacity: 0.3;
		}
	}
}