.q-div {
	text-align: left;
	font-size: 1.4rem;
	display: flex;
	justify-content: center;
}

.q-content {
    padding-left: 10.0%;
    padding-right: 6.0%;
}

.q-choice-prev {
	color: #FFFFFF;
	height: 10.0%;
	background-color: #061C3A;
	border-style: solid;
	border-width: 0.3rem;
	border-color: #FFFFFF;
	padding: 1.5rem;
	white-space: nowrap;
}

.prev-ttl {
	font-size: 1.3rem;
	padding-left: 1.0rem;
}

.prev-bdy {
	text-align: right;
	font-size: 1.2rem;
	padding-left: 40.0%;
}

/* Impact level adjustment notification */
.noti {
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
	margin-left: 18.0%;
	max-width: 65.0%;
}

.sec-title-div {
	font-size: 1.9rem;
	text-align: center;
	font-weight: bold;
	padding-top: 2.5%;
	padding-bottom: 2.5%;
}

/* Actual progress inside of frame */
.progress-bar {
	text-align: center;
	color: #FFFFFF;
	font-size: 1.5rem;
	background-color: #14315C;
	min-width: 12.0rem;
	border-style: solid;
	border-color: #FFFFFF;
	border-radius: 1.0rem;
	border-width: 0.1rem;
}

/* Progress frame */
.progress {
	margin-left: 20%;
	border-style: solid;
	border-radius: 1.5rem;
	border-width: 0.3rem;
	border-color: #14315C;
}

h4 {
	font-size: 1.5rem;
	text-align: center;
	padding-top: 5.0%;
	padding-bottom: 3.0%;
}

.sv_complete_btn, .sv_next_btn, .sv_prev_btn, .sv_preview_btn {
    font-size: 1.4rem;
	background-color: #061C3A;
	color: #ffffff;
	width: 7.5rem;
	border-style: solid;
    border-width: 0.2rem;
	border-radius: 1.5rem;
}

h5 {
    font-size: 1.5rem;
}

/* Alert when question is unanswered */
.sv_qstn_error_top {
	/* Color=> Tomato Red (#FF6347)*/
	text-align: center;
	font-size: 1.2rem;
	color: #FFFFFF;
	width: 35.0%;
	min-width: 18.0rem;
	background-color: #FF6347;
	border-color: #FF6347;
	border-style: solid;
	border-radius: 1.5rem;
	border-width: 0.3rem;
	margin: 0.5rem 0 0.5rem 0;
}

.sv_row {
	white-space: normal;
}

.sv_qcbc.form-inline, .sv_qcbx.form-inline {
	padding-top: 1.0%;
	padding-left: 7.0%;
	text-indent: -2.0%;
}

.card-footer {
	text-align: center;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}