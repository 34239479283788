.inv-logo {
	width: 5.0rem;
	padding-right: 1.5%;
}

.main-title {
	color: #FFFFFF;
	font-size: 3.5rem;
}

.title-box {
	display: flex;
	padding-top: 1.5%;
	justify-content: center;
}

.loginOutBtn {
	margin-left: auto;
	align-self: center;
	margin-right: 3.0%;
}

.visibility-hidden {
	visibility: hidden;
}

.header {
	background-color: #061C3A;
}

nav ul {
	list-style: none;
	display: flex;
	margin-bottom: .5rem;
	justify-content: center;
}

nav ul li {
	font-size: 1.3rem;
	padding-inline-start: 2.0%; /* 2rem */
	padding-inline-end: 2.0%; /* 2rem */
	padding-top: 0.5rem;
	padding-bottom: 1.5rem;
}

nav ul li a {
	color: #E7C470;
}

.active {
	border-bottom: 4px solid Gold;
}