*, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
}

a {
    text-decoration: none; /* Remove hyperlink underline */
}

html {
    font-family: 'Muli', sans-serif;
    text-align: center;
    background-color: #F3F3F3;
    object-fit: cover;
}

.plume {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}